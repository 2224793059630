const actions = {
  CHECK_AUTHORIZATION_REQUEST: 'CHECK_AUTHORIZATION_REQUEST',
  CHECK_AUTHORIZATION_SUCCESS: 'CHECK_AUTHORIZATION_SUCCESS',
  CHECK_AUTHORIZATION_FAILURE: 'CHECK_AUTHORIZATION_FAILURE',

  SEND_OTP_REQUEST: 'SEND_OTP_REQUEST',
  SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
  SEND_OTP_FAILURE: 'SEND_OTP_FAILURE',

  REGISTRATION_REQUEST: 'REGISTRATION_REQUEST',
  REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
  REGISTRATION_FAILURE: 'REGISTRATION_FAILURE',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  VALIDATE_AGENT_REQUEST: 'VALIDATE_AGENT_REQUEST',
  VALIDATE_AGENT_SUCCESS: 'VALIDATE_AGENT_SUCCESS',
  VALIDATE_AGENT_FAILURE: 'VALIDATE_AGENT_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  VERIFY_LOGIN_NUMBER_REQUEST: 'VERIFY_LOGIN_NUMBER_REQUEST',
  VERIFY_LOGIN_NUMBER_SUCCESS: 'VERIFY_LOGIN_NUMBER_SUCCESS',
  VERIFY_LOGIN_NUMBER_FAILURE: 'VERIFY_LOGIN_NUMBER_FAILURE',

  VERIFY_OTP_REQUEST: 'VERIFY_OTP_REQUEST',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE',

  VALIDATE_PIN_REQUEST: 'VALIDATE_PIN_REQUEST',
  VALIDATE_PIN_SUCCESS: 'VALIDATE_PIN_SUCCESS',
  VALIDATE_PIN_FAILURE: 'VALIDATE_PIN_FAILURE',

  UPDATE_PIN_REQUEST: 'UPDATE_PIN_REQUEST',
  UPDATE_PIN_SUCCESS: 'UPDATE_PIN_SUCCESS',
  UPDATE_PIN_FAILURE: 'UPDATE_PIN_FAILURE',

  UPDATE_OLD_PIN_REQUEST: 'UPDATE_OLD_PIN_REQUEST',
  UPDATE_OLD_PIN_SUCCESS: 'UPDATE_OLD_PIN_SUCCESS',
  UPDATE_OLD_PIN_FAILURE: 'UPDATE_OLD_PIN_FAILURE',

  CREATE_PIN_REQUEST: 'CREATE_PIN_REQUEST',
  CREATE_PIN_SUCCESS: 'CREATE_PIN_SUCCESS',
  CREATE_PIN_FAILURE: 'CREATE_PIN_FAILURE',

  LOGOUT_GUARDIAN: 'LOGOUT_GUARDIAN',
  LOGOUT_GUARDIAN_SUCCESS: 'LOGOUT_GUARDIAN_SUCCESS',

  UPDATE_IS_GUARDIAN_AGENT: 'UPDATE_IS_GUARDIAN_AGENT',

  RESET_AUTH_STORE: 'RESET_AUTH_STORE',

  checkAuthorization: (data) => ({
    type: actions.CHECK_AUTHORIZATION_REQUEST,
    data
  }),
  login: (payload) => ({
    type: actions.LOGIN_REQUEST,
    payload
  }),
  sendOtp: (payload) => ({
    type: actions.SEND_OTP_REQUEST,
    payload
  }),
  verifyLoginNumber: (payload) => ({
    type: actions.VERIFY_LOGIN_NUMBER_REQUEST,
    payload
  }),
  verifyOtp: (payload) => ({
    type: actions.VERIFY_OTP_REQUEST,
    payload
  }),
  validatePin: (payload) => ({
    type: actions.VALIDATE_PIN_REQUEST,
    payload
  }),
  updatePin: (payload) => ({
    type: actions.UPDATE_PIN_REQUEST,
    payload
  }),
  updateOldPin: (payload) => ({
    type: actions.UPDATE_OLD_PIN_REQUEST,
    payload
  }),
  createPin: (payload) => ({
    type: actions.CREATE_PIN_REQUEST,
    payload
  }),
  registerAgent: (payload) => ({
    type: actions.REGISTRATION_REQUEST,
    payload
  }),
  loginAgent: (payload) => ({
    type: actions.LOGIN_REQUEST,
    payload
  }),
  validateAgent: (payload) => ({
    type: actions.VALIDATE_AGENT_REQUEST,
    payload
  }),
  updateIsGuardianAgent: (payload) => ({
    type: actions.UPDATE_IS_GUARDIAN_AGENT,
    payload
  }),
  logout: (payload) => ({
    type: actions.LOGOUT_REQUEST,
    payload
  }),
  logoutSuccess: () => ({
    type: actions.LOGOUT_SUCCESS
  }),
  logoutGuardian: (payload) => ({
    type: actions.LOGOUT_GUARDIAN,
    payload
  }),
  resetAuthStore: (payload) => ({
    type: actions.RESET_AUTH_STORE,
    payload
  })
}

export default actions
