import { Component } from 'react'
import { CustomTextWrapper } from './index.style'

class CustomText extends Component {
  render () {
    const {
      children, color, size, weight, textAlign, gtmElementId,
      top, bottom, lineHeight, className, padLeft, padRight,
      bgColor,textDecoration, ...restProps
    } = this.props

    return (
      <CustomTextWrapper
        fontColor={color}
        fontSize={size}
        fontWeight={weight}
        textAlign={textAlign}
        paddingTop={top}
        padLeft={padLeft}
        padRight={padRight}
        data-gtm-element-id={gtmElementId}
        marginBottom={bottom}
        lineHeight={lineHeight}
        bgColor={bgColor}
        textDecoration={textDecoration}
        className={className}
        {...restProps}
      >
        {children}
      </CustomTextWrapper>
    )
  }
}

export default CustomText
