import { Component } from 'react'
import { PublicFooterWrapper } from './index.style'

class PublicFooter extends Component {
  handleRedirect = () => {
    const { page } = this.props
    this.props.history.push((page === 'login' || page === 'otp-login' || page === 'pin-login') ? '/registration' : '/login')
  }

  renderFooter = () => {
    const { page } = this.props
    switch (page) {
      case 'login':
        return (
          <div>
            Belum menjadi Mitra PasarPolis?
            <span onClick={this.handleRedirect}>Daftar</span>
          </div>
        )
      case 'pin-login':
        return (
          <div onClick={this.handleRedirect}>
            Daftar Menjadi Mitra
          </div>
        )
      case 'register':
      case 'otp-register':
        return (
          <div>
            Sudah menjadi Mitra PasarPolis?
            <span onClick={this.handleRedirect}>Masuk</span>
          </div>
        )
      case 'otp-login':
        return (
          <div className='otp-footer'>
            Belum punya akun?
            <span onClick={this.handleRedirect}>Daftar sekarang</span>
          </div>
        )
      case 'customer':
        return (
          <div>
            Layanan WA 24/7 : +62 878 900 8080
          </div>
        )
      default:
        return (
          <div>
            Sudah menjadi Mitra PasarPolis?
            <span onClick={this.handleRedirect}>Masuk</span>
          </div>
        )
    }
  }

  render () {
    return (
      <PublicFooterWrapper bottom={this.props.bottom} fullBorder={this.props.fullBorder} className={this.props.className} page={this.props.page}>
        {this.renderFooter()}
      </PublicFooterWrapper>
    )
  }
}

export default PublicFooter
