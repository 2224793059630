import actions from './action'
import { clearToken } from '../../helpers/utility'
import Cookies from 'universal-cookie'
import { LOGIN_AS_GURDIAN } from '../../constants/cookies'

const cookies = new Cookies()

const initState = {
  agent_token: '',
  account_status: false,
  loginAgentStatus: {},
  guardianLogOutStatus: '',
  sendOtpStatus: {},
  validateAgentStatus: {},
  verifyNumberStatus: {},
  verifyOtpStatus: {},
  createPinStatus: {},
  validatePinStatus: {},
  loggedIn: false,
  isVNAgent: window.location.origin.includes('mitra-vn') || (process.env.REACT_APP_CC === 'VN'),
  isGuardianAgentLogin: cookies.get(LOGIN_AS_GURDIAN)
}

export default function authReducer (state = initState, action) {
  switch (action.type) {
    case actions.CHECK_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        agent_token: action.agent_token,
        checkAuthorizationStatus: true,
        agent_code: action.agent_code
      }
    case actions.CHECK_AUTHORIZATION_FAILURE:
      return {
        ...state,
        checkAuthorizationStatus: false
      }
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        loginAgentStatus: action.data,
        checkAuthorizationStatus: true,
        agent_token: action.data.auth_token
      }
    case actions.LOGIN_FAILURE:
      return {
        ...state,
        checkAuthorizationStatus: false,
        loginAgentStatus: action.data
      }
    case actions.VALIDATE_AGENT_SUCCESS:
    case actions.VALIDATE_AGENT_FAILURE:
      return {
        ...state,
        validateAgentMessage: action.message,
        validateAgentStatus: action.data
      }
    case actions.REGISTRATION_SUCCESS:
      return {
        ...state,
        registerAgentStatus: action.data,
        // checkAuthorizationStatus: true,
        agentDetails: action.data
        // agent_token: action.data.auth_token
      }
    case actions.REGISTRATION_FAILURE:
      return {
        ...state,
        registerAgentStatus: action.data
      }

    case actions.LOGOUT:
      return initState
    case actions.SEND_OTP_SUCCESS:
    case actions.SEND_OTP_FAILURE:
      return {
        ...state,
        sendOtpStatus: action.data
      }
    case actions.VERIFY_LOGIN_NUMBER_SUCCESS:
    case actions.VERIFY_LOGIN_NUMBER_FAILURE:
      return {
        ...state,
        verifyNumberStatus: action.data
      }
    case actions.VERIFY_OTP_SUCCESS:
    case actions.VERIFY_OTP_FAILURE:
      return {
        ...state,
        verifyOtpStatus: action.data
      }
    case actions.VALIDATE_PIN_SUCCESS:
    case actions.VALIDATE_PIN_FAILURE:
      return {
        ...state,
        validatePinStatus: action.data
      }
    case actions.UPDATE_PIN_SUCCESS:
    case actions.UPDATE_PIN_FAILURE:
      return {
        ...state,
        updatePinStatus: action.data
      }
    case actions.UPDATE_OLD_PIN_SUCCESS:
    case actions.UPDATE_OLD_PIN_FAILURE:
      return {
        ...state,
        updateOldPinStatus: action.data
      }
    case actions.CREATE_PIN_SUCCESS:
    case actions.CREATE_PIN_FAILURE:
      return {
        ...state,
        createPinStatus: action.data
      }
    case actions.UPDATE_IS_GUARDIAN_AGENT:
      return {
        ...state,
        isGuardianAgentLogin: action.payload
      }
    case actions.LOGOUT_SUCCESS:
      clearToken()
      return {
        ...initState,
        logoutStatus: 'success',
        checkAuthorizationStatus: false
      }
    case actions.LOGOUT_GUARDIAN_SUCCESS:
      return {
        ...state,
        isGuardianAgentLogin: '',
        guardianLogOutStatus: 'success'
      }
    case actions.RESET_AUTH_STORE: {
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        const stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state,
          ...initState
        }
      }
    }
    default:
      return state
  }
}
