import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { InnerHeaderWrapper } from './index.style'
import {
  CLOSE_BLACK_ICON, PASAR_MITRA_BB, NOTIFICATION, FAQ_QUESTION,
  PLUS, BACK_NEW_ICON, SAVE_ICON, GREEN_CHECK, TRASH_ICON, INFORMATION_NEW
} from '../../images'
import { LANG_STRINGS } from '../../lang'
import { DisplayFlexRCC, DisplayFlexRCSB } from '../../commonStyle/index.style'
import SideMenu from '../SideMenu'
import SearchBar from '../SearchBar'
import LoaderSvg from '../CircularLoader/LoaderSvg'
class InnerHeader extends Component {
  state = {
    isGreenIcon: false,
    isHideBackBtn: false
  }

  componentDidUpdate () {
    const { saveLoader } = this.props
    if (saveLoader && !this.state.isGreenIcon) {
      this.firstTimer = setTimeout(() => {
        this.setState({
          isGreenIcon: true
        }, () => {
          this.secondTimer = setTimeout(() => {
            this.setState({
              isGreenIcon: false
            })
          }, 300)
        })
      }, 100)
    }
  }

  componentWillUnmount () {
    if (this.firstTimer) {
      clearTimeout(this.firstTimer)
      clearTimeout(this.secondTimer)
    }
  }

  handleRoutes = (route) => {
    this.props.history.push(route)
  }

  handleBackClick = () => {
    const { onBackClick } = this.props
    if (onBackClick) {
      onBackClick()
    } else {
      this.props.history.goBack()
    }
  }

  getFaqsNode = () => (
    <DisplayFlexRCC
      className='help-btn'
      id='dashboard4'
      onClick={() => this.handleRoutes('/help')}
    >
      {LANG_STRINGS.help}
    </DisplayFlexRCC>
  )

  handleHideBack = () => {
    this.setState(oldState => ({
      ...oldState,
      isHideBackBtn: !oldState.isHideBackBtn
    }))
  }

  render () {
    const {
      heading, location, hideBackButton, showFaq, headerPosition,
      displayBackButton, showNotificationIcon, showCustomerFaq,
      agentDetails = {}, showSideMenuIcon, sideMenuItems, showAddIcon,
      onAddIconClick, showSearchBar, onSearch, handleShowSearchDesc, backBtnId,
      showCloseIcon, showSaveIcon, onClose, onSaveClick, disabledSave,
      saveLoader, showDeleteIcon, onDeleteClick, searchPlaceHolder, showInfoIcon, onInfo, backgroundColor
    } = this.props
    const { isHideBackBtn } = this.state

    const showBackButton = location.pathname.match('[a-z]/[a-z]')

    return (
      <InnerHeaderWrapper headerPosition={headerPosition} backgroundColor={backgroundColor}>
        <DisplayFlexRCSB className='inner'>
          {
            showFaq && this.getFaqsNode()
          }
          {
            ((showBackButton && !hideBackButton) || displayBackButton) && !isHideBackBtn &&
              <div className='back'>
                <img src={BACK_NEW_ICON} alt='icon' className='img-width' onClick={this.handleBackClick} id={backBtnId} />
              </div>
          }
          <div className='head-container'>
            {
              heading === 'logo' ? (
                <img className='pp-logo' width={168} height={18} src={PASAR_MITRA_BB} alt='pasarpolis' />
              )
                : (<div className='heading'>{heading}</div>)
            }
          </div>
          {
            showNotificationIcon && (
              <div className='notification-icon' id='dashboard5'>
                <img src={NOTIFICATION} width={20} height={20} alt='icon' onClick={() => this.handleRoutes('/notifications')} />
                {
                  (agentDetails.notification_unread_count > 0) && (
                    <div className='green-dot' />
                  )
                }
              </div>
            )
          }
          {
            showSideMenuIcon &&
              <div className='side-menu-icon'>
                <SideMenu sideMenuItems={sideMenuItems} />
              </div>
          }
          {
            showAddIcon &&
              <div className='close-cross'>
                <img src={PLUS} alt='' onClick={onAddIconClick} />
              </div>
          }
          {
            showSearchBar &&
              <div className='close-cross'>
                <SearchBar
                  onSearch={onSearch}
                  handleShowSearchDesc={handleShowSearchDesc}
                  onSearchClick={this.handleHideBack}
                  searchPlaceHolder={searchPlaceHolder}
                />
              </div>
          }
          {
            showSaveIcon && (
              <div className='save-icon'>
                {
                  saveLoader ? (<LoaderSvg size='24px' isLoader />) : (
                    <div className='saved-icon-pos'>
                      {
                        this.state.isGreenIcon && (
                          <div className='saved-done'>
                            <img src={GREEN_CHECK} alt='' width='10px' />
                          </div>
                        )
                      }
                      <img
                        src={SAVE_ICON} alt=''
                        className={`img-width ${disabledSave && 'save-blur'}`}
                        onClick={() => !disabledSave && onSaveClick()}
                      />
                    </div>
                  )
                }
              </div>
            )
          }
          {
            showCloseIcon && (
              <div className='close-cross'>
                <img src={CLOSE_BLACK_ICON} alt='' className='img-width' onClick={onClose} />
              </div>
            )
          }
          {
            showInfoIcon && (
              <div className='close-cross'>
                <img src={INFORMATION_NEW} alt='' className='img-width' onClick={onInfo} />
              </div>
            )
          }
          {
            showCustomerFaq && (
              <div className='faq-icon' onClick={() => this.handleRoutes('/customer/faqs')}>
                <img src={FAQ_QUESTION} width={20} height={20} alt='icon' onClick={this.handleTOFaq} />
              </div>
            )
          }
          {
            showDeleteIcon && (
              <div className='delete-icon' onClick={onDeleteClick}>
                <img src={TRASH_ICON} width={20} height={20} alt='delete-icon' />
              </div>
            )
          }
          {/* {
            showRightFaq && this.getFaqsNode()
          } */}
        </DisplayFlexRCSB>
      </InnerHeaderWrapper>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      agentDetails: state.User.agentDetails
    }),
    {

    }
  )(InnerHeader)
)
