import styled from 'styled-components'
import { AgentColorPallette } from '../../configs/colorPallete'

export const CustomTextWrapper = styled.div`
  color: ${props => props.fontColor || AgentColorPallette.font};
  font-size: ${props => props.fontSize || '12px'};
  font-weight: ${props => props.fontWeight || 'normal'};
  text-align: ${props => props.textAlign || 'left'};
  padding-top: ${props => props.paddingTop || '0px'};
  padding-left: ${props => props.padLeft || '0px'};
  padding-right: ${props => props.padRight || '0px'};
  margin-bottom: ${props => props.marginBottom || '2px'};
  padding-bottom: ${props => props.padBottom || '0'};
  line-height: ${props => props.lineHeight || 'inherit'};
  background-color: ${props => props.bgColor || 'inherit'};
  text-decoration: ${props => props.textDecoration || 'none'};
`
