import { useGlobalContextValue } from '../../../contextProvider/GlobalContext'
import { useServiceRequest } from '@pasarpolis/common-react-hooks'
import {
  checkAuthorization, getAgentDetails, getGlobalHeaders,
  updateGlobalState, logoutAgentRequest, getAgentBadges, resetInitialState,
  saveCustomerData, addDeviceFCMToken, getBranchList, getAllProducts,
  getProducts, showAgentPICInfo, getPartnerToken, getVerificationDoc,
  verifyAgentEmail, getOfflineProductList, getAgentGwp,
  deleteDraft
} from '../actions'
import { getHeader } from '../../../helpers/utility'
import { useCallback } from 'react'

export const useGlobalServiceCall = (actionsCall = () => {}) => {
  const [globalState, dispatch] = useGlobalContextValue()
  const [callApi] = useServiceRequest(dispatch)

  const request = useCallback(async (payload = {}) => {
    await callApi(actionsCall({
      ...payload
    }))
  }, [callApi, actionsCall])

  return [globalState, request]
}

// update Global Context value
export const useUpdateGlobal = () => {
  const [globalState, dispatch] = useGlobalContextValue()
  const updateGlobalStore = useCallback((payload) => dispatch(updateGlobalState(payload)), [dispatch])

  return [globalState, updateGlobalStore]
}

// save customer data like customer_address, name, mobile_no
export const useSaveCustomerData = () => {
  const [, dispatch] = useGlobalContextValue()
  return useCallback((payload) => dispatch(saveCustomerData(payload)), [dispatch])
}

// reset global store
export const useResetGlobalState = () => {
  const [, dispatch] = useGlobalContextValue()
  return () => dispatch(resetInitialState())
}

// check is login user or normal user
export const useCheckAuthorization = () => useGlobalServiceCall(checkAuthorization)

// Get use details
export const useGetAgentDetails = () => useGlobalServiceCall(getAgentDetails)

// Get provider Branch list
export const useGetBranchList = () => useGlobalServiceCall(getBranchList)

// Get Agent Badges Data
export const useGetAgentBadges = () => useGlobalServiceCall(getAgentBadges)

// Get Agent GWP Data
export const useGetAgentGWP = () => useGlobalServiceCall(getAgentGwp)

// get All Products so that we can use icons on other places
export const useGetAllProducts = () => useGlobalServiceCall(getAllProducts)

export const useGetProducts = () => useGlobalServiceCall(getProducts)

// Get Global Headers
export const useGetGlobalHeaders = () => {
  const [globalState, dispatch] = useGlobalContextValue()
  const updateGlobalHeaders = () => dispatch(getGlobalHeaders(getHeader()))

  return [globalState, updateGlobalHeaders]
}

// get all offline to online product list

export const useGetOfflineProductList = () => useGlobalServiceCall(getOfflineProductList)

// add fcm token
export const useAddFCMToken = () => useGlobalServiceCall(addDeviceFCMToken)

// logout agent handler
export const useAgentLogout = () => useGlobalServiceCall(logoutAgentRequest)

// get verification docs
export const useGetVerificationDoc = () => useGlobalServiceCall(getVerificationDoc)

// email verification
export const useAgentEmailVerification = () => useGlobalServiceCall(verifyAgentEmail)

export const useDeleteDraft = () => useGlobalServiceCall(deleteDraft)

// show Agent PIC Info
export const useShowAgentPIC = () => {
  const [globalState, dispatch] = useGlobalContextValue()
  const updateShowPICInfo = () => dispatch(showAgentPICInfo())

  return [globalState, updateShowPICInfo]
}

export const useGetPartnerToken = () => useGlobalServiceCall(getPartnerToken)
