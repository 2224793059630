import { Component } from 'react'
import PropTypes from 'prop-types'
import { NumericInputStyle } from './index.style'
import NumberFormat from 'react-number-format'

class NumericInput extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    amountPrefix: PropTypes.string,
    id: PropTypes.string,
    errorField: PropTypes.string,
    displayBorder: PropTypes.bool,
    backgroundColor: PropTypes.string,
    opacity: PropTypes.string,
    disabled: PropTypes.bool,
    isSkipRegex: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    showError: PropTypes.string,
    placeholder: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number
  }

  constructor (props) {
    super(props)
    this.state = {
      value: '',
      isEdit: false
    }
  }

  componentDidMount () {
    if (this.props.value) {
      this.setState({
        value: this.props.value
      })
    }
  }

  handleInputChange = (e) => {
    const { type, isSkipRegex } = this.props
    const re = type === 'phone_number' ? /\+?\0?(62)?(62-)?[0-9]{0,17}$/ : /^[0-9\b]+$/
    if ((e.target.value === '' || re.test(e.target.value)) || isSkipRegex) {
      this.setState({
        value: e.target.value,
        isEdit: true
      })
      this.props.onChange(e.target.value)
    }
  }

  handleDiscount = (data) => {
    const { maxLength, maxValue } = this.props
    const value = data.floatValue
    const intValue = parseInt(value)
    const discountError = (`${intValue}`.length > maxLength) || (value > maxValue)
    this.setState({
      value: data.floatValue,
      discountError,
      isEdit: true
    })
    this.props.onChange(discountError ? '' : data.floatValue)
  }

  getValidDiscount = (data) => {
    const { maxLength = 5, maxValue = 100 } = this.props
    const { value } = data
    const intValue = parseInt(value)
    if (value && (`${intValue}`.length <= maxLength) && intValue <= maxValue) return data
    if (!value) {
      this.setState({
        value: intValue,
        discountError: true,
        isEdit: true
      })
      this.props.onChange('')
    }
    return false
  }

  handleValueChange = ({ value }) => {
    if (value < 0) return null
    this.setState({
      value: value,
      isEdit: true
    })

    if (value) {
      this.props.onChange(value)
    } else {
      this.props.onChange('')
    }
  }

  resetInputValue = () => {
    this.setState(() => {
      return { value: '' }
    })
    if (this.NumericInputRef) {
      setTimeout(() => {
        this.NumericInputRef.value = ''
      }, 50)
    }
  }

  render () {
    const {
      name, placeholder, errorField, backgroundColor, wrapperClass, type,
      opacity, className, displayBorder, value, disabled, defaultValue,
      amountPrefix, onBlur = () => {}, showError, isNotSuffix, currencySymbol,
      isAllowed, decimal, errorCenter
    } = this.props
    let initialValue = defaultValue
    if (this.state.isEdit) {
      initialValue = ''
    }
    return (
      <NumericInputStyle
        error={errorField === name}
        displayBorder={displayBorder}
        backgroundColor={backgroundColor}
        opacity={opacity}
        className={wrapperClass}
        errorCenter={errorCenter}
      >
        {
          type === 'phone_number' ? (
            <input
              name={name}
              value={this.state.value || value || initialValue}
              placeholder={placeholder}
              className={`numeric-input ${className}`}
              disabled={disabled}
              onBlur={onBlur}
              inputMode='numeric'
              onChange={this.handleInputChange}
            />
          )
            : (type === 'amount')
              ? (
                <NumberFormat
                  thousandSeparator={currencySymbol === 'Rp' ? '.' : ','}
                  decimalSeparator={currencySymbol === 'Rp' ? ',' : '.'}
                  prefix={amountPrefix || 'Rp '}
                  name={name}
                  defaultValue={initialValue}
                  value={value || this.state.value || initialValue}
                  placeholder={placeholder}
                  className={`numeric-input ${className}`}
                  disabled={disabled}
                  onBlur={onBlur}
                  allowNegative={false}
                  isAllowed={isAllowed}
                  getInputRef={(element) => {
                    this.NumericInputRef = element
                  }}
                  onValueChange={this.handleValueChange}
                  decimalScale={0}
                />
              )
              : (type === 'discount') ? (
                <NumberFormat
                  name={name}
                  defaultValue={initialValue}
                  value={this.state.value}
                  placeholder={placeholder}
                  allowNegative={false}
                  className={`numeric-input ${className}`}
                  disabled={disabled}
                  onValueChange={this.handleDiscount}
                  decimalScale={decimal || 2}
                  isAllowed={this.getValidDiscount}
                  getInputRef={(element) => {
                    this.NumericInputRef = element
                  }}
                  suffix={isNotSuffix ? '' : '%'}
                />
              ) : (
                <NumberFormat
                  name={name}
                  value={this.state.value || value || initialValue}
                  placeholder={placeholder}
                  className={`numeric-input ${className}`}
                  disabled={disabled}
                  onChange={this.handleInputChange}
                  decimalScale={0}
                  getInputRef={(element) => {
                    this.NumericInputRef = element
                  }}
                />
              )
        }
        {
          showError && <span className='number-error'>{showError}</span>
        }
      </NumericInputStyle>
    )
  }
}

export default NumericInput
