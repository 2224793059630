import moment from 'moment'

export function checkEmpty (item) {
  if (item === '' || !item) {
    return true
  }
  return false
}

export function typeValid (type, inputValue = '') {
  // trim the text
  const value = typeof inputValue === 'string' ? inputValue.trim() : inputValue
  switch (type) {
    case 'multiple_select':
      if (value?.length) {
        return true
      }
      return false
    case 'phone_number':
      if (value.match(/^\+?0?(62)?(62-)?(84)?(84-)?[0-9]{7,17}$/)) {
        return true
      }
      return false
    case 'chasis_number':
      if (value.match(/^[0-9A-Za-z]{8,20}$/)) {
        return true
      }
      return false
    case 'machine_number':
      if (value.match(/^[0-9A-Za-z]{5,21}$/)) {
        return true
      }
      return false
    case 'ktp':
    case 'ktp_no':
      if (value.match(/^[0-9]{16}$/) || value.match(/^[0-9]{18}$/)) {
        return true
      }
      return false
    case 'npwp':
      if (value.match(/^[0-9]{15,16}$/)) {
        return true
      }
      return false
    case 'postal_code':
      if (value && value.match(/^[0-9]{5}$/)) {
        return true
      }
      return false
    case 'name': {
      // we are validate the name strings now
      const isNumber = value.match(/[\d+]/g)
      const isSlash = value.match(/\//g)
      const isUnderscore = value.match(/_/g)
      if (value.match(/^[A-Za-z]{1}/) && value.match(/^[A-Za-z.]{1}[\s/.\w]{0,}$/)) {
        if (isNumber && (isNumber.length > 5)) return false
        if (isSlash && (isSlash.length > 1)) return false
        if (isUnderscore && (isUnderscore.length > 1)) return false
        return true
      }
      return false
    }
    case 'string':
    case 'textarea':
    case 'input':
      if (value && value.length > 2) {
        return true
      }
      return false
    case 'amount':
    case 'number': {
      const stringValue = value.toString()
      if (stringValue.match(/^\d+$/)) {
        return true
      }
      return false
    }
    case 'email':
      if (value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        return true
      }
      return false
    case 'disclaimer':
    case 'radio':
    case 'radioCard':
    case 'autosuggest':
    case 'autoSearchModal':
    case 'select':
    case 'upload':
    case 'uploadFileButton':
    case 'dob':
    case 'datePicker':
    case 'autoSearchBottomSheet':
      if (value) {
        return true
      }
      return false
    case 'uploadBulkImgButton':
      return value && value?.length
    case 'vehicle_plate_no':
    case 'plate_no': {
      const valueWithoutSpace = value.replace(/ /g, '')
      // ^[0-9]{1,4}[A-Za-z]{0,3}$
      if (valueWithoutSpace.match(/^([0-9]{1,4})([A-Za-z]{0,3})$/)) {
        return true
      }
      return false
    }
    case 'color':
      if (value && value.trim().length > 2 && value.trim().length <= 35) {
        return true
      }
      return false
    default:
      return !!value
  }
}

export const checkDobValidity = (date, min, max, minType = 'month', maxType = 'year') => {
  // date is the Date Object selected by the customer/Agent
  // min: minimum date range
  // minType: type is days, month, years
  // max: max in number
  // maxType: type is days, month, year
  const isValidDate = moment(date, 'DD/MM/YYYY', true)
  if (!isValidDate._isValid) return false

  const dtDOB = new Date(moment(date, 'DD/MM/YYYY'))
  const dtCurrent = new Date()
  const isMinTypeDays = minType === 'days'
  const isMinTypeMonth = minType === 'month'
  const isMinTypeYear = minType === 'year'

  // check maxType:
  const yearDiff = dtCurrent.getFullYear() - dtDOB.getFullYear()
  const monthDiff = dtCurrent.getMonth() - dtDOB.getMonth()
  const dateDiff = dtCurrent.getDate() - dtDOB.getDate()
  const isCurrentMonthSmall = dtCurrent.getMonth() < dtDOB.getMonth()
  const isCurrentDateSmall = dtCurrent.getDate() < dtDOB.getDate()

  if (isMinTypeYear) {
    if (yearDiff < min) return false

    if ((yearDiff > min) && (yearDiff < max)) {
      return true
    } else if (yearDiff === max && (monthDiff <= 0)) {
      if (dateDiff <= 0) return true
      return false
    } else if (yearDiff === min && (monthDiff >= 0)) {
      if (dateDiff >= 0) {
        return true
      }
      return false
    }
    return false
  } else if (isMinTypeMonth) {
    // this handle if min type month
    if (yearDiff <= 0) {
      if (monthDiff === min) {
        if (dateDiff < 0) return false
        return true
      } else if (monthDiff > min) {
        return true
      }
      return false
    }

    if (yearDiff === 1) {
      if (monthDiff < 0 && isCurrentMonthSmall) {
        if (isCurrentDateSmall && dateDiff <= 0) {
          return true
        } else if (!isCurrentDateSmall && dateDiff >= 0) {
          return true
        }

        return false
      }

      if (monthDiff >= min) {
        return true
      }
      return true
    }

    if (yearDiff < max) {
      return true
    } else if (yearDiff === max) {
      if (monthDiff < 0) {
        return true
      } else if (monthDiff === 0) {
        if (dateDiff <= 0) return true
        return false
      }
    }
    return false
  } else if (isMinTypeDays) {
    const nextMonthValidDate = moment().add(max, 'day')
    const momentCrDate = moment()
    const momentInputDate = moment(date, 'DD/MM/YYYY')
    if ((momentInputDate.isAfter(momentCrDate) && momentInputDate.isBefore(nextMonthValidDate)) ||
      (date === nextMonthValidDate.format('DD/MM/YYYY') || date === momentCrDate.format('DD/MM/YYYY'))
    ) {
      return true
    }

    // if (dateDiff < min && (yearDiff === 0)) {
    //   return false
    // }
    // if (yearDiff < max) {
    //   return true
    // } else if (yearDiff === max) {
    //   if (monthDiff < 0) {
    //     return true
    //   } else if (monthDiff === 0) {
    //     if (dateDiff <= 0) return true
    //     return false
    //   }
    // }

    return false
  }

  return true
}

export function checkUserDobValid (dob, age) {
  // dob is valid date new Date() returns value
  const currentDt = new Date()
  const yearDiff = currentDt.getFullYear() - dob.getFullYear()

  if (yearDiff <= age) {
    if (yearDiff === age) {
      // if year diff exact 50 then check month is greater
      const monthDiff = currentDt.getMonth() - dob.getMonth()
      const dateDiff = currentDt.getDate() - dob.getDate()
      if ((monthDiff < 0) || ((monthDiff === 0) && (dateDiff <= 0))) {
        return true
      } else {
        return false
      }
    }
    return true
  }
  return false
}

export function isFormFilled (formData, formKeys) {
  let formValid = true
  if (formData) {
    formKeys.forEach(function (item) {
      if (!formData[item]) {
        formValid = false
      }
    })
    return formValid
  }
}
