// import FormItemLabel from '../FormItemLabel'
import { DatePickerStyle } from './index.style'
import { useCallback, useState, useEffect, useRef } from 'react'
import { Datepicker, setOptions } from '@mobiscroll/react'
// import { Label } from '../Input/index.style'
import moment from 'moment'

setOptions({
  theme: 'ios',
  themeVariant: 'light'
})

function DatePicker ({
  label, name, required, minDate, maxDate,
  getSubText, onChange = () => { }, error,
  defaultValue, invalid = '', onClick, validator
}) {
  const defaultSelection = defaultValue || new Date()
  const [selected, setSelected] = useState()
  // const [showLabel, setShowLabel] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    if (defaultValue && !selected) {
      setSelected(defaultValue)
      onChange(defaultValue)
    }
  }, [selected, name, onChange, defaultValue])

  const handleOnChange = useCallback((e) => {
    const value = moment(e.value).format('YYYY-MM-DD')
    if (validator) {
      if (!validator(value)) {}
    }
    setSelected(value)
    onChange(value)
  }, [name, onChange, validator])

  const onClickHandler = (e) => {
    // setShowLabel(true)
    onClick && onClick()
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (!selected) {
        // setShowLabel(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [
    selected
  ])

  return (
    <DatePickerStyle
      ref={ref}
      error={error}
      onClick={onClickHandler}
    >
      {/* <Label active={selected || showLabel}>
        {label}
      </Label> */}
      <Datepicker
        touchUi
        dateFormat='DD/MM/YYYY'
        inputComponent='input'
        controls={['date']}
        className='date-input'
        defaultSelection={defaultSelection}
        inputProps={{
          placeholder: 'Tanggal Lahir',
          className: 'md-mobile-picker-input'
        }}
        themeVariant='light'
        min={minDate}
        max={maxDate}
        defaultValue={selected}
        onChange={handleOnChange}
      />
      {/* {
        getSubText && (
          <CustomText
            color={AgentColorPallette.fontGrey}
            size='0.7em'
            top='0.4rem'
            className='display-flex'
          >
            <img src={MINI_INFO} alt='Info' className='align-self' />&nbsp;&nbsp;<span>{getSubText(selected)}</span>
          </CustomText>
        )
      } */}
      {/* <Error>{error}</Error> */}
    </DatePickerStyle>
  )
}

export default DatePicker
