import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './action'
import Cookies from 'universal-cookie'
import postApi from '../../methods/post'
import { ConfigUrl } from '../../configs/url'
import { getErrorMessage } from '../../helpers/utility'
import userActions from '../user/action'
import policyActions from '../policy/action'
import authPost from '../../methods/authPost'
import { LOGIN_AS_GURDIAN } from '../../constants/cookies'
import { saveFCMTokenBridge } from '../../bridges'

const cookies = new Cookies()

export function * checkAuthorization () {
  yield takeEvery(actions.CHECK_AUTHORIZATION_REQUEST, function * ({ data }) {
    try {
      if ((data.agent_token)) {
        yield put({
          type: actions.CHECK_AUTHORIZATION_SUCCESS,
          agent_token: true,
          agent_code: data.agent_code
        })
        if (!['/dashboard'].includes(window.location.pathname)) {
          yield put({
            type: userActions.GET_AGENT_DETAILS_REQUEST,
            payload: {
              ...data
            }
          })
        } else if (data.agent_token && data.data?.id) {
          yield put({
            type: userActions.GET_AGENT_DETAILS_SUCCESS,
            data: data.data,
            message: 'success'
          })
        }
      } else {
        yield put({
          type: actions.CHECK_AUTHORIZATION_FAILURE,
          agent_token: false,
          token: null
        })
      }
    } catch (error) {
      // handle the error state here if you want to
      yield put({
        type: actions.CHECK_AUTHORIZATION_FAILURE,
        agent_token: false,
        token: null
      })
    }
  })
}

export function * sendOtp () {
  yield takeEvery(actions.SEND_OTP_REQUEST, function * (payload) {
    try {
      const res = yield call(postApi, { ...payload, url: ConfigUrl.sendOtp })
      if (res) {
        yield put({
          type: actions.SEND_OTP_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.SEND_OTP_FAILURE,
          data: getErrorMessage(res.status, res.data.error_code)
        })
      }
    } catch (res) {
      yield put({
        type: actions.SEND_OTP_FAILURE,
        data: getErrorMessage(res.status, res.data.error_code)
      })
    }
  })
}

export function * verifyLoginNumber () {
  yield takeEvery(actions.VERIFY_LOGIN_NUMBER_REQUEST, function * (payload) {
    try {
      const res = yield call(postApi, { ...payload, url: ConfigUrl.verifyNumber })
      if (res) {
        yield put({
          type: actions.VERIFY_LOGIN_NUMBER_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.VERIFY_LOGIN_NUMBER_FAILURE,
          data: res.data
        })
      }
    } catch (res) {
      yield put({
        type: actions.VERIFY_LOGIN_NUMBER_FAILURE,
        data: res.data
      })
    }
  })
}

export function * verifyOtp () {
  yield takeEvery(actions.VERIFY_OTP_REQUEST, function * (payload) {
    try {
      const res = yield call(postApi, { ...payload, url: ConfigUrl.verifyOtp })
      if (res) {
        yield put({
          type: actions.VERIFY_OTP_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.VERIFY_OTP_FAILURE,
          data: res.data
        })
      }
    } catch (res) {
      yield put({
        type: actions.VERIFY_OTP_FAILURE,
        data: res.data
      })
    }
  })
}

export function * validatePin () {
  yield takeEvery(actions.VALIDATE_PIN_REQUEST, function * (payload) {
    try {
      const res = yield call(postApi, { ...payload, url: ConfigUrl.validatePin })
      if (res) {
        yield put({
          type: actions.VALIDATE_PIN_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.VALIDATE_PIN_FAILURE,
          data: res.data
        })
      }
    } catch (res) {
      yield put({
        type: actions.VALIDATE_PIN_FAILURE,
        data: res.data
      })
    }
  })
}
export function * updatePin () {
  yield takeEvery(actions.UPDATE_PIN_REQUEST, function * (payload) {
    try {
      const res = yield call(postApi, { ...payload, url: ConfigUrl.updatePin })
      if (res) {
        yield put({
          type: actions.UPDATE_PIN_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.UPDATE_PIN_FAILURE,
          data: res.data
        })
      }
    } catch (res) {
      yield put({
        type: actions.UPDATE_PIN_FAILURE,
        data: res.data
      })
    }
  })
}
export function * updateOldPin () {
  yield takeEvery(actions.UPDATE_OLD_PIN_REQUEST, function * (payload) {
    try {
      const res = yield call(postApi, { ...payload, url: ConfigUrl.updateOldPin })
      if (res) {
        yield put({
          type: actions.UPDATE_OLD_PIN_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.UPDATE_OLD_PIN_FAILURE,
          data: res.data
        })
      }
    } catch (res) {
      yield put({
        type: actions.UPDATE_OLD_PIN_FAILURE,
        data: res.data
      })
    }
  })
}

export function * createPin () {
  yield takeEvery(actions.CREATE_PIN_REQUEST, function * (payload) {
    try {
      const res = yield call(postApi, { ...payload, url: ConfigUrl.createNewPin })
      if (res) {
        yield put({
          type: actions.CREATE_PIN_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.CREATE_PIN_FAILURE,
          data: res.data
        })
      }
    } catch (res) {
      yield put({
        type: actions.CREATE_PIN_FAILURE,
        data: res.data
      })
    }
  })
}

export function * validateAgent () {
  yield takeEvery(actions.VALIDATE_AGENT_REQUEST, function * (payload) {
    try {
      const res = yield call(postApi, { ...payload, url: ConfigUrl.validateAgent })
      if (res) {
        yield put({
          type: actions.VALIDATE_AGENT_SUCCESS,
          message: 'success',
          data: res.data
        })
      } else {
        yield put({
          type: actions.VALIDATE_AGENT_FAILURE,
          message: getErrorMessage(res.status, res.data.error_code),
          data: getErrorMessage(res.status, res.data.error_code)
        })
      }
    } catch (res) {
      yield put({
        type: actions.VALIDATE_AGENT_FAILURE,
        message: getErrorMessage(res.status, res.data.error_code),
        data: getErrorMessage(res.status, res.data.error_code)
      })
    }
  })
}

export function * registerAgent () {
  yield takeEvery(actions.REGISTRATION_REQUEST, function * (payload) {
    try {
      const res = yield call(postApi, { ...payload, url: ConfigUrl.createAgent })
      if (res) {
        yield put({
          type: actions.REGISTRATION_SUCCESS,
          data: res.data
        })
        yield put({
          type: userActions.GET_AGENT_DETAILS_SUCCESS,
          data: res.data
        })
        yield put({
          type: userActions.GET_PRODUCT_LIST_REQUEST
        })
        yield put({
          type: policyActions.GET_APPLICATION_LIST_REQUEST
        })
      } else {
        yield put({
          type: actions.REGISTRATION_FAILURE,
          data: getErrorMessage(res.status, res.data.error_code)
        })
      }
    } catch (res) {
      yield put({
        type: actions.REGISTRATION_FAILURE,
        data: getErrorMessage(res.status, res.data.error_code)
      })
    }
  })
}

export function * loginAgent () {
  yield takeEvery(actions.LOGIN_REQUEST, function * (payload) {
    try {
      const res = yield call(postApi, { ...payload, url: ConfigUrl.loginAgent })
      if (res) {
        // yield cookies.set('agent_code', res.data.agent_code, { path: '/' })
        // yield cookies.set('agent_token', res.data.auth_token, { path: '/' })
        yield put({
          type: actions.LOGIN_SUCCESS,
          data: res.data
        })
        yield put({
          type: userActions.GET_PRODUCT_LIST_REQUEST
        })
      } else {
        yield put({
          type: actions.LOGIN_FAILURE,
          data: getErrorMessage(res.status, res.data.error_code)
        })
      }
    } catch (res) {
      yield put({
        type: actions.LOGIN_FAILURE,
        data: getErrorMessage(res.status, res.data.error_code)
      })
    }
  })
}

export function * logout () {
  yield takeEvery(actions.LOGOUT_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, {
        url: ConfigUrl.logout,
        body: {
          fcm_token: payload.fcmToken
        }
      })
      if (res) {
        yield put({
          type: actions.LOGOUT_SUCCESS
        })
        saveFCMTokenBridge('')
      } else {
        yield put({
          type: actions.LOGOUT_FAILURE
        })
      }
    } catch (res) {
      yield put({
        type: actions.LOGOUT_FAILURE
      })
    }
  })
}

export function * logoutGuardian () {
  yield takeEvery(actions.LOGOUT_GUARDIAN, function * ({ payload }) {
    cookies.remove(LOGIN_AS_GURDIAN, {
      path: '/'
    })
    // if payload true then redirect to dashboard page
    if (payload) {
      window.location.href = '/dashboard'
    }
    yield put({
      type: actions.LOGOUT_GUARDIAN_SUCCESS
    })
  })
}

export default function * rootSaga () {
  yield all([
    fork(checkAuthorization),
    fork(sendOtp),
    fork(registerAgent),
    fork(loginAgent),
    fork(validateAgent),
    fork(logoutGuardian),
    fork(logout),
    fork(verifyLoginNumber),
    fork(verifyOtp),
    fork(validatePin),
    fork(updatePin),
    fork(updateOldPin),
    fork(createPin)
  ])
}
