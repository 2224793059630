import styled from 'styled-components'

export const DatePickerStyle = styled.div`
    position: relative;

    // border: ${props => props.error ? '1px solid #DC0000' : ''};
    border: ${props => props.error ? '2px solid' + props.theme.errorDark : 'none'};
    border-radius: 12px;

    .md-mobile-picker-input{
      // background: #FFF;
      // border: none;
      // height: 56px;
      // width: 100%;
      // width: -moz-available;          
      // width: -webkit-fill-available; 
      // width: fill-available;
      // border-radius: 12px;
      // outline: none;
      // padding-left: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      background-color: #F5F5F5;
      opacity: 0.8;
      margin-bottom: 12px;
      width: -webkit-fill-available;
      width: -moz-available;
      font-size: 14px;
      color: ${props => props.error ? props.theme.errorDark : props.theme.black};
      border: ${props => props.error ? '2px solid' + props.theme.errorDark : 'none'};
      padding: 12px;
      letter-spacing: 4px;
      text-align: center;
      font-weight: 600;
      &:focus {
        outline: none;
      }
      ::placeholder {
        color: ${props => props.theme.fontGrey};
        font-size: 14px;
        letter-spacing: 2px;
      }
    }

    
      
    
`
